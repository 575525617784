import Perfil from "../../src/assets/img/Perfil.jpg";
import Facebook from "./Icons/Facebook";
import Instagram from "./Icons/Instagram";
import WhatsApp from "./Icons/WhatsApp";
import Select from "./Select";
import MagnifyingGlass from "./Icons/MagnifyingGlass";

const Jumbotron = ({
  header,
  subHeader,
  setSelectedCategory,
  selectedCategory,
  searchTerm,
  handleSearch
}) => {
  return (
    <div className="shadow-xl pb-1 rounded-lg mb-5">
      <div className="flex flex-col items-center justify-between p-8 bg-white h-[45vh] sm:flex-row sm:flex-wrap">
        {/* Logo */}
        <div className="w-36 h-36 border-4 border-slate-200 shadow-xl shadow-purple-200 rounded-full sm:w-48 sm:h-48 sm:mr-8">
          <img
            src={Perfil}
            className="h-full w-full rounded-full object-cover"
            alt="Perfil"
          />
        </div>

        {/* Headers */}
        <div className="text-center mb-2 sm:text-left sm:flex-1 sm:mb-0">
          <h1 className="font-bold text-4xl mb-2">{header}</h1>
          <h4 className="font-semibold text-lg mb-2 sm:mb-4">{subHeader}</h4>

          {/* Ícones de redes sociais */}
          <div className="flex justify-center mb-2 w-full sm:justify-start space-x-4">
            <a
              href="https://www.instagram.com/adereco_beachwear/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button>
                <Instagram />
              </button>
            </a>
            <a
              href="https://www.facebook.com/aderecobeach"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button>
                <Facebook />
              </button>
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=556434113857"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button>
                <WhatsApp />
              </button>
            </a>
          </div>
        </div>

        {/* Select Component */}
      </div>
      <div className="w-full sm:w-auto mt-2 sm:mt-8 sm:self-center sm:col-span-2 ">
        <Select
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
        />
      </div>
      <div className="m-4 relative">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
          className="border border-slate-300 shadow-sm rounded-full pl-10 pr-4 py-2 w-full"
          placeholder="Buscar..."
        />
        <MagnifyingGlass Class={"absolute left-3 top-1/2 transform -translate-y-1/2"} />
      </div>
    </div>
  );
};

export default Jumbotron;
