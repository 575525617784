const Select = ({ setSelectedCategory, selectedCategory }) => {
    return (
      <div className="m-3">
        <label
          htmlFor="categories"
          className="block mb-2 px-2 text-sm font-medium text-gray-900 dark:text-white"
        >
          Escolha uma categoria
        </label>
        <select
          id="categories"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="">Todas</option>
          <option value="praia">Praia</option>
          <option value="fitness">Fitness</option>
          <option value="masculino">Masculino</option>
          <option value="infantil">Infantil</option>
          <option value="natação">Natação</option>
          <option value="dança">Dança</option>
          <option className={"bg-black text-white font-bold"} value="promocao">OUTLET</option>
        </select>
      </div>
    );
  };
  
  export default Select;
  