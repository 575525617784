import React, { useState, useEffect } from "react";
import Jumbotron from "./components/Jumbotron";
import GridImagens from "./components/GridImagens";
import ImageModal from "./components/ImageModal";

function App() {
  function sortByDateAndPromotion(imgs) {
    // Primeiro, separar as imagens em dois grupos
    const promocionais = imgs.filter((img) => img.promocao);
    const naoPromocionais = imgs.filter((img) => !img.promocao);

    // Função de ordenação por data
    const sortByDate = (a, b) => new Date(b.createdAt) - new Date(a.createdAt);

    // Ordenar cada grupo separadamente
    const promocionaisOrdenadas = promocionais.sort(sortByDate);
    const naoPromocionaisOrdenadas = naoPromocionais.sort(sortByDate);

    // Concatenar os arrays, mantendo as não promocionais primeiro
    return [...naoPromocionaisOrdenadas, ...promocionaisOrdenadas];
  }

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [filteredImages, setFilteredImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);
  const [imgs, setImgs] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [visitaRegistrada, setVisitaRegistrada] = useState(false);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const registrarVisita = () => {
    const visitData = {
      visitDate: new Date().toISOString(),
    };

    fetch("https://catalogo.aderecobeachwear.com.br/api/visita", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(visitData),
    })
      .then((response) => response.json())
      .then((data) => {
        setVisitaRegistrada(true);
      })
      .catch((error) => console.error("Erro ao registrar visita:", error));
  };

  useEffect(() => {
    if (!visitaRegistrada) {
      registrarVisita();
    }
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const categoriaParam = urlParams.get("categoria");
    const searchParam = urlParams.get("search");

    if (categoriaParam) {
      setSelectedCategory(categoriaParam);
    }
    if (searchParam) {
      setSearchTerm(searchParam);
    }

    fetch("https://catalogo.aderecobeachwear.com.br/api/imagens")
      .then((response) => response.json())
      .then((data) => {
        setImgs(data);
        setFilteredImages(data);
      })
      .catch((error) => console.error("Erro ao buscar:", error));
  }, []);

  const openModal = (image) => {
    setModalImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (isModalOpen) {
      window.history.pushState(null, null, window.location.pathname);
    }

    const handlePopstate = () => {
      if (isModalOpen) {
        closeModal();
      }
    };

    window.addEventListener("popstate", handlePopstate);

    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, [isModalOpen]);

  useEffect(() => {
    let resultImages = imgs;
    if (selectedCategory) {
      if (selectedCategory === "promocao") {
        resultImages = imgs.filter((img) => img.promocao);
      } else {
        resultImages = imgs.filter((img) =>
          img.imagem_da_categoria?.includes(selectedCategory)
        );
      }
    }
    setFilteredImages(sortByDateAndPromotion(resultImages));
    if (searchTerm) {
      resultImages = resultImages.filter((img) => {
        const term = searchTerm.toLowerCase();
        const categoriaMatch = img.imagem_da_categoria.some((categoria) =>
          categoria.toLowerCase().includes(term)
        );
        const marcaMatch = img.marca.toLowerCase().includes(term);
        const descricaoMatch = img.descricao.toLowerCase().includes(term);

        return categoriaMatch || marcaMatch || descricaoMatch;
      });
    }

    setFilteredImages(sortByDateAndPromotion(resultImages));
  }, [selectedCategory, imgs, searchTerm]);

  // Função de debounce
  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm);
    }, 1000);
    return () => clearTimeout(timerId);
  }, [searchTerm]);

  // Função para registrar a categoria selecionada
  const registrarCategoria = (categoria) => {
    fetch("https://catalogo.aderecobeachwear.com.br/api/logcategoria", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ categoria }),
    })
      .then((response) => response.json())
      .catch((error) => console.error("Erro ao registrar categoria:", error));
  };

  const registrarBusca = (searchTerm) => {
    fetch("https://catalogo.aderecobeachwear.com.br/api/logsearchtearm", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ searchTerm }),
    })
      .then((response) => response.json())
      .catch((error) =>
        console.error("Erro ao registrar termo de busca:", error)
      );
  };

  // Registrar categoria selecionada quando ela mudar
  useEffect(() => {
    if (selectedCategory) {
      registrarCategoria(selectedCategory);
    }
  }, [selectedCategory]);

  // Registrar termo de busca quando o debouncedSearchTerm mudar
  useEffect(() => {
    if (debouncedSearchTerm) {
      registrarBusca(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  return (
    <div>
      <Jumbotron
        header={"ADEREÇO"}
        subHeader={"Beach & Fitness"}
        setSelectedCategory={setSelectedCategory}
        selectedCategory={selectedCategory}
        searchTerm={searchTerm}
        handleSearch={handleSearch}
      />
      <GridImagens filteredImages={filteredImages} openModal={openModal} />
      {isModalOpen && <ImageModal image={modalImage} closeModal={closeModal} />}
      <div className="m-5"></div>
    </div>
  );
}

export default App;
