import React, { useState, useEffect } from "react";
import Image from "./Image";
import Spinner from "./Spinner";

const GridImagens = ({ filteredImages, openModal }) => {
  const [imagesToShow, setImagesToShow] = useState(8);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 500 && !isLoading) {
        setIsLoading(true);
        setTimeout(() => {
          setImagesToShow(prev => prev + 8);
          setIsLoading(false);
        }, 2000);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  return (
    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mx-3">
      {filteredImages.slice(0, imagesToShow).map((img, index) => (
        <div key={index} className="grid gap-4">
          <div>
            <Image img={img} openModal={openModal} />
          </div>
        </div>
      ))}
      {isLoading && (
        <div className="flex justify-center m-5 w-[80vw]">
          <Spinner />
        </div>
      )}
    </div>
  );
};

export default GridImagens;
