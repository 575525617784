import { useState } from "react";
import Spinner from "./Spinner";

const Image = ({ img, openModal }) => {
  const [visivel, setVisivel] = useState(false);

  const registrarClique = (img) => {
    fetch("https://catalogo.aderecobeachwear.com.br/api/cliques", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        imageId: img.nomeArquivo,
      }),
    })
      .then((response) => response.json())
      .catch((error) => console.error("Erro ao registrar clique:", error));
  };

  const handleClick = (img) => {
    openModal(img);
    registrarClique(img); // Registrar o clique aqui
  };

  return (
    <div className="relative flex justify-center items-center h-auto max-w-full">
      {/* Spinner */}
      {!visivel && (
        <div className="h-16 max-w-full flex justify-center items-center">
          <Spinner />
        </div>
      )}

      {/* Image */}
      <img
        className={`h-auto max-w-full rounded-lg transition-opacity duration-1000 ${
          visivel ? "opacity-100" : "opacity-0"
        }`}
        src={`https://catalogo.aderecobeachwear.com.br/api/${img.nomeArquivo}`}
        alt={img.descricao}
        onClick={() => handleClick(img)}
        onLoad={() => setVisivel(true)}
      />
    </div>
  );
};

export default Image;
