import Pin from "./Icons/Pin";
import WhatsApp from "./Icons/WhatsApp";
import Xmark from "./Icons/Xmark";

const ImageModal = ({ image, closeModal }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-75 z-50 overflow-y-auto p-4">
      <div className="bg-white p-4 rounded-lg relative h-[80vh] md:h-[90vh] lg:h-[100vh] max-w-md mx-auto flex flex-col lg:max-w-[50%] shadow-black shadow-lg">
        <button
          onClick={closeModal}
          className="absolute top-2 right-2 text-lg font-bold"
        >
          <Xmark />
        </button>

        {/* Parte destinada para a imagem - agora flex-grow controla o crescimento */}
        <div className="flex-grow flex items-center justify-center overflow-hidden">
          <img
            src={`https://catalogo.aderecobeachwear.com.br/api/${image.nomeArquivo}`}
            alt=""
            className="max-h-full object-contain"
          />
        </div>

        {/* OUTROS ELEMENTOS - Ficam fixos na parte de baixo */}
        <div className="flex flex-col justify-between mt-1">
          <div className="font-semibold bg-gray-200 mt-1 text-xs py-1 px-2 rounded-full inline-block whitespace-nowrap max-w-max">
            {image.marca}
          </div>
          <p className="my-2">{image.descricao}</p>
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-2 mt-1">
            <button
              onClick={() =>
                window.open(
                  "https://maps.app.goo.gl/xeJ9wpC2927i9ADF9",
                  "_blank"
                )
              }
              className="flex items-center px-3 py-2 justify-center bg-amber-500 text-white rounded-lg w-full sm:w-auto shadow-slate-500 shadow-lg"
            >
              <Pin Class={"mr-3"} />
              VISITAR LOJA
            </button>
            <button
              onClick={() => {
                const imageUrl = `https://catalogo.aderecobeachwear.com.br/api/${image.nomeArquivo}`;
                const message = `Olá! Amei esse produto: ${image.descricao}\nVeja a imagem aqui: ${imageUrl}`;
                const urlFriendlyMessage = encodeURIComponent(message);
                const whatsappUrl = `https://api.whatsapp.com/send?phone=556434113857&text=${urlFriendlyMessage}`;
                window.open(whatsappUrl, "_blank");
              }}
              className="flex items-center px-3 py-2 justify-center bg-green-600 text-white rounded-lg w-full sm:w-auto shadow-slate-500 shadow-lg"
            >
              <WhatsApp Class={"mr-3"} />
              SAIBA MAIS
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageModal;
